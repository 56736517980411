var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addFlows)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"lg":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('input-form',{attrs:{"placeholder":"Write flow name","validate":'required|max:50',"name":"Flow name","label":'Flow Name',"limit":50},model:{value:(_vm.flows.name),callback:function ($$v) {_vm.$set(_vm.flows, "name", $$v)},expression:"flows.name"}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"md":"12"}},[_c('input-form',{attrs:{"placeholder":"Any required experience or equipment for the flow","validate":'required',"name":"Flow requirements","label":'Requirements',"limit":"2000"},model:{value:(_vm.flows.requirements),callback:function ($$v) {_vm.$set(_vm.flows, "requirements", $$v)},expression:"flows.requirements"}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"md":"4"}},[_c('validation-provider',{staticClass:"flex-grow-1",attrs:{"name":"EGP price","rules":{ required: true, regex: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Price'}},[_c('b-input-group',{attrs:{"append":"EGP"}},[_c('b-form-input',{class:[{ 'is-invalid': errors.length > 0 }],attrs:{"placeholder":"000.00"},model:{value:(_vm.flows.price_egp),callback:function ($$v) {_vm.$set(_vm.flows, "price_egp", $$v)},expression:"flows.price_egp"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"mb-5 pt-4 mt-3 text-center",attrs:{"md":"4"}},[_c('b-form-checkbox',{staticClass:"custom-checkbox-color-check mb-2 mr-sm-2 mb-sm-0",attrs:{"type":"checkbox","color":"warning"},model:{value:(_vm.selectedEGP),callback:function ($$v) {_vm.selectedEGP=$$v},expression:"selectedEGP"}},[_vm._v(" Discounted Price ")])],1),_c('b-col',{staticClass:"mb-3",attrs:{"md":"4"}},[_c('validation-provider',{staticClass:"flex-grow-1",attrs:{"name":"Discounted EGP price","rules":{ regex: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, required: _vm.selectedEGP }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Discounted Price'}},[_c('b-input-group',{attrs:{"append":"EGP"}},[_c('b-form-input',{class:[{ 'is-invalid': errors.length > 0}],attrs:{"placeholder":"000.00","disabled":!_vm.selectedEGP},model:{value:(_vm.flows.discount_price_egp),callback:function ($$v) {_vm.$set(_vm.flows, "discount_price_egp", $$v)},expression:"flows.discount_price_egp"}})],1),(!_vm.flows.discount_price_egp)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),(Number(_vm.flows.discount_price_egp) > Number(_vm.flows.price_egp))?_c('small',{staticClass:"text-danger"},[_vm._v(" More than price ")]):_vm._e()],1)]}}],null,true)})],1)],1),_c('div',{staticClass:"position-relative"},[_c('span',{staticClass:"d-flex position-absolute add_position w-100"},[_c('span',{staticClass:"text-warning cursor-pointer ml-auto p-2",on:{"click":_vm.addInstructor}},[_vm._v("+ Add another")])]),_vm._l((_vm.flows.instructors),function(instructor,counter){return _c('div',{key:counter},[_c('validation-provider',{staticClass:"flex-grow-1",attrs:{"name":"Instructor","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"inline":"","label":'Instructor',"label-for":'Instructor'}},[_c('b-form-row',[_c('b-col',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('b-form-input',{class:[{ 'is-invalid': errors.length > 0 }],attrs:{"placeholder":"First Name","name":("First name " + (counter+1))},model:{value:(instructor.first_name),callback:function ($$v) {_vm.$set(instructor, "first_name", $$v)},expression:"instructor.first_name"}})],1),_c('b-col',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('b-form-input',{class:[{ 'is-invalid': errors.length > 0 }],attrs:{"placeholder":"Last Name","name":("Last name " + (counter+1))},model:{value:(instructor.last_name),callback:function ($$v) {_vm.$set(instructor, "last_name", $$v)},expression:"instructor.last_name"}})],1),_c('b-col',[(counter != 0)?_c('span',{staticClass:"deleteLabelButton text-danger cursor-pointer",on:{"click":function($event){return _vm.deleteInstructor(counter)}}},[_vm._v("Delete")]):_vm._e()])],1)],1)]}}],null,true)})],1)})],2)],1),_c('b-col',{staticClass:"mb-3",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Pick Level"}},[_c('div',{staticClass:"d-flex flex-wrap mb-2 flex-grow-1 justify-content-around"},_vm._l((_vm.options),function(option,counter){return _c('span',{key:counter},[_c('button',{staticClass:"btn radio-btn",class:("radio-btn-" + (option.color) + " " + (_vm.selectLevel(option.value) ? 'radio-btn-selected-'+option.color : '')),on:{"click":function($event){$event.preventDefault();_vm.flows.level = option.value}}},[_vm._v(" "+_vm._s(option.text)+" ")])])}),0)]),_c('validation-provider',{staticClass:"flex-grow-1",attrs:{"name":"Description","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Description"}},[_c('b-form-textarea',{class:[{ 'is-invalid': errors.length > 0 }],attrs:{"label":'Description',"placeholder":"Write your description about this flow….","rows":"4"},model:{value:(_vm.flows.description),callback:function ($$v) {_vm.$set(_vm.flows, "description", $$v)},expression:"flows.description"}})],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-5",attrs:{"md":"12"}},[_c('cropper-images',{attrs:{"label":"Upload Photos","removeLoadingUi":_vm.removeLoadingUi,"progressLoading":_vm.progressBar,"images":_vm.flows.images,"type":"flow_image"},on:{"cropper-save":_vm.saveGalleryImage,"remove-image":_vm.removeGalleryImage}})],1)],1),(_vm.typeOfModal != 'view')?_c('b-row',[_c('b-col',{staticClass:"mt-4",attrs:{"md":"12"}},[(_vm.typeOfModal == 'add')?_c('div',{staticClass:"d-flex justify-content-center"},[(!_vm.requestLoading)?_c('b-button',{staticClass:"button-orange-modal",attrs:{"type":"submit"}},[_c('i',{staticClass:"las la-plus"})]):_c('b-button',{staticClass:"button-orange-modal"},[_c('spinner-loading')],1)],1):_vm._e(),(_vm.typeOfModal == 'edit')?_c('div',{staticClass:"d-flex justify-content-center"},[(!_vm.requestLoading)?_c('b-button',{staticClass:"button-blue-modal",attrs:{"type":"submit"}},[_c('i',{staticClass:"las la-pen"})]):_c('b-button',{staticClass:"button-blue-modal"},[_c('spinner-loading')],1)],1):_vm._e()])],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }